import React, { FC } from 'react';
import { useAppDispatch } from '../../redux/hooks';
import { addModalToList, removeModalFromList } from '../../redux/slices/modalSlice';
import logoBrubank from '../../assets/images/logo_brubank.png';

const AdvanceSettlementPage: FC = () => {
  const dispatch = useAppDispatch();

  const handleAdvanceSettlement = (): void => {
    const messageModal = () => (
      <div>
        <div>
          <p className="modal-title">¡Atención!</p>
        </div>
        <div className="mt-5 mb-3 container-body">
          <p className="body-text">¿Estas seguro/a de querer realizar el adelanto de liquidaciones?</p>
        </div>
        <div className="d-flex flex-row justify-content-around">
          <div className="modal-button-cancel" onClick={() => dispatch(removeModalFromList())} role="none">
            <div className="modal-button-text">
              <p>Volver</p>
            </div>
          </div>
          <div className="modal-button-ok" onClick={handleAdvanceSettlement} role="none">
            <div className="modal-button-text">
              <p>Confirmar</p>
            </div>
          </div>
        </div>
        <div>
          <img src={logoBrubank} width={86} height={86} alt="logo_brubank" className="logo-brubank" />
        </div>
      </div>
    );
    dispatch(
      addModalToList({
        hideModalAccept: true,
        body: messageModal(),
      }),
    );
  };

  return (
    <>
      <div className="advance-settlement-page">
        <div className="header-advance-settlement">
          <div className="header-text">
            <p className="header-total">Total</p>
            <p className="header-liquidation">Liquidación</p>
          </div>
          <p className="header-amount">$275 mil</p>
        </div>
        <div className="details">
          <div className="details-row">
            <p className="details-label">Fecha</p>
            <p className="details-value">12 / 08 / 2024</p>
          </div>
          <div className="details-row">
            <p className="details-label">Tasa efectiva</p>
            <p className="details-value rate">2,21%</p>
          </div>
        </div>
        <div className="single-settlement-advance">
          <div className="divider">{}</div>
          <div className="settlement-button" onClick={handleAdvanceSettlement} role="none">
            <div className="settlement-button-text">
              <p>Realizar adelanto</p>
            </div>
          </div>
          <div className="settlement-details px-4">
            <div className="settlement-row">
              <p className="settlement-title-label">Liquidación</p>
              <p className="settlement-amount">$140 mil</p>
            </div>
            <div className="d-flex flex-row justify-content-between mb-4">
              <p className="settlement-date-label">Fecha</p>
              <p className="settlement-date-value-label">12 / 08 / 2024</p>
            </div>
            <div className="settlement-row">
              <p className="settlement-label">Costo de adelantamiento</p>
              <p className="settlement-value advance-cost">$6.077,5</p>
            </div>
            <div className="settlement-row">
              <p className="settlement-label">Neto de adelanto</p>
              <p className="settlement-value advance-net">268.922,5</p>
            </div>
            <div className="settlement-row mb-4">
              <p className="settlement-label">Adelanto en días</p>
              <p className="settlement-value">2</p>
            </div>
          </div>
        </div>
        <div className="total-advance">
          <div className="divider">{}</div>
          <div className="total-advance-ask" onClick={handleAdvanceSettlement} role="none">
            <div className="total-advance-button-text-ask">
              <p>¿Desea realizar un adelanto total de la fecha?</p>
            </div>
          </div>
          <div className="total-advance-button" onClick={handleAdvanceSettlement} role="none">
            <div className="total-advance-button-text">
              <p>Realizar adelanto total</p>
            </div>
          </div>
          <div className="total-advance-details px-4">
            <div className="total-advance-row">
              <p className="total-advance-label">Costo de adelantamiento</p>
              <p className="total-advance-value">$4.221</p>
            </div>
            <div className="total-advance-row">
              <p className="total-advance-label">Neto de adelanto</p>
              <p className="total-advance-value">$186.779</p>
            </div>
            <div className="total-advance-row">
              <p className="total-advance-label">Adelanto en días</p>
              <p className="settlement-value">5</p>
            </div>
          </div>
        </div>
        <div className="powered-by me-3">
          <p>powered by</p>
          <p className="brand">nubceo</p>
        </div>
      </div>
    </>
  );
};

export default AdvanceSettlementPage;
