import React, { FC, ReactElement, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { Field, Form, Formik } from 'formik';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import moment from 'moment';
import { BsFillExclamationTriangleFill } from 'react-icons/bs';
import {
  FormButtonComponent,
  LinkComponent,
  SelectInputComponent,
  TextInputComponent,
  CheckBoxComponent,
  Paragraph,
  Subtitle,
  PasswordInputComponent,
  RandomImageComponent,
} from '../../components';
import { countriesApp, regexCodes, regexCountryCodes, operations, countryCodeOptions } from '../../utils/constants';
import { UserRegisterData } from '../../utils/interfaces';
import variables from '../../scss/_exports.module.scss';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { registerAsync } from '../../redux/slices/authSlice';
import { FormFieldType, StorageVars } from '../../utils/types';
import { NeedHelpLink } from '../../components/buttons';
import logoNubceo from '../../assets/images/logoAzul.png';
import { getActivities } from '../../services/activitiesService';
import logger from '../../services/loggerService';
// import { EVENTS } from '../../utils/segment/events';
// import { segmentTrack } from '../../utils/segment/hooks';
import TelephoneInput from '../../components/inputs/TelephoneInput';

interface Activity {
  code: string;
  name: string;
}

const RegisterPage: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { loading } = useAppSelector((state) => state.auth);
  const { referral } = useParams<{ referral: string }>();
  const dispatch = useAppDispatch();
  const [activities, setActivities] = useState<Array<Activity>>([]);
  const [redirectTiendanube, setRedirectTiendanube] = useState('');
  const activity = activities
    .map((item, index) => ({ id: index, name: item.name, value: item.code.trim() }))
    .sort((item) => (item.name === 'Contador' ? 1 : -1));
  const getActivitiesRequest = async (): Promise<void> => {
    try {
      const { data: res } = await getActivities();
      setActivities(res.data.documents);
    } catch (error) {
      console.log('getActivitiesRequest');
    }
  };

  useEffect(() => {
    const redirectFromTiendanube = localStorage.getItem(StorageVars.redirectFromTiendanube);
    getActivitiesRequest();
    if (location.state?.fromLogin) {
      // segmentTrack(EVENTS.AUTH.LOGIN.REGISTER);
    }
    if (redirectFromTiendanube) {
      setRedirectTiendanube('PARTIENDANUBE');
    }
  }, []);

  useEffect(() => {
    if (loading === 'succeeded') {
      navigate('/company-on-boarding');
    }
  }, [loading]);

  const handleSubmitForm = (values: UserRegisterData): void => {
    try {
      const registerPayload = {
        firstName: values.firstName,
        sureName: values.sureName,
        password: values.password,
        passwordConfirm: values.password,
        email: values.email,
        countryCode: values.countryCode,
        phone: `${values.countryCodeNumber}${values.phone}`,
        activityCode: values.activityCode,
        referral: referral || redirectTiendanube || undefined,
        operationType: values.operationType,
      };
      if (!registerPayload.referral) delete registerPayload.referral;
      dispatch<void>(registerAsync(registerPayload));
      (window as any).lintrk('track', { conversion_id: 10423506 });
    } catch (err) {
      logger.error('registerUser.error', err);
    }
  };

  const initialValues: UserRegisterData = {
    firstName: '',
    sureName: '',
    password: '',
    passwordConfirm: '',
    email: '',
    countryCode: '',
    phone: '',
    referral: undefined,
    terms: false,
    activityCode: '',
    operationType: '',
    countryCodeNumber: '',
  };

  const SignupSchema = Yup.object().shape({
    sureName: Yup.string()
      .required('Debés escribir tu apellido.')
      .max(20, 'Tu apellido debe tener menos de 20 caracteres.')
      .matches(regexCodes.nameRegex, 'Debés ingresar un apellido válido.'),
    firstName: Yup.string()
      .required('Debés escribir tu nombre.')
      .max(20, 'Tu nombre debe tener menos de 20 caracteres.')
      .matches(regexCodes.nameRegex, 'Debés ingresar un nombre válido.'),
    password: Yup.string()
      .matches(regexCodes.password, 'Tu contraseña debe tener al menos 8 caracteres, 1 número y 1 carácter especial.')
      .max(255, 'Tu contraseña debe tener menos de 255 caracteres.')
      .test('not-email', 'La contraseña no puede ser igual al email.', (value, context) => {
        const { email } = context.parent;
        return value !== email;
      })
      .required('Debés escribir una contraseña.'),
    email: Yup.string()
      .email('Debés ingresar un email válido.')
      .required('Debés escribir tu email.')
      .max(250, 'Tu email debe tener menos de 250 caracteres.'),
    countryCode: Yup.string().required('Debés seleccionar tu país.'),
    phone: Yup.string()
      .matches(regexCodes.phone, 'Debés ingresar un teléfono válido.')
      .min(5, 'Debés ingresar un teléfono de al menos 5 caracteres.')
      .max(11, 'Debés ingresar un teléfono de menos de 11 caracteres.')
      .required('Debés escribir un teléfono.'),
    activityCode: Yup.string().required('Debés seleccionar tu actividad.'),
    operationType: Yup.string().required('Debés seleccionar tu operación.'),
    countryCodeNumber: Yup.string().required('Debés seleccionar el código de área de tu pais.'),
    terms: Yup.boolean().isTrue('Debés aceptar los Términos y Condiciones.'),
  });

  return (
    <div className="h-100 register-page w-100 d-flex flex-column flex-md-row">
      <div className="col-xs-12 col-md-6 p-2 p-sm-5 section">
        <Formik
          initialValues={initialValues}
          validationSchema={SignupSchema}
          onSubmit={(values): void => {
            handleSubmitForm(values);
          }}
        >
          {({ errors, dirty, values, setFieldValue, touched }): ReactElement => (
            <Form className="container flex-grow-1 d-flex flex-column justify-content-center">
              <>
                <div className="d-flex flex-row flex-wrap mb-2 mb-sm-5">
                  <div className="col-12 col-lg-7">
                    <img src={logoNubceo} alt="Fiserv" className="img-fluid w-75 mb-3" />
                    <Subtitle size="md">Creá tu cuenta de nubceo</Subtitle>
                    <Paragraph>
                      ¿Ya tenés tu cuenta?{' '}
                      <LinkComponent className="link" route={{ pathname: '/login', state: { fromRegister: true } }}>
                        ¡Ingresá!
                      </LinkComponent>
                    </Paragraph>
                  </div>
                </div>
                <div className="row">
                  <Field name="firstName">
                    {({ field }: FormFieldType): ReactElement => (
                      <TextInputComponent
                        required
                        containerClassName="container-input col-12 col-xl-6"
                        placeholder="Ingresá tu nombre"
                        label="Nombre"
                        errorMessage={touched.firstName && errors.firstName}
                        {...field}
                      />
                    )}
                  </Field>
                  <Field name="sureName">
                    {({ field }: FormFieldType): ReactElement => (
                      <TextInputComponent
                        required
                        containerClassName="container-input col-12 col-xl-6"
                        placeholder="Ingresá tu apellido"
                        label="Apellido"
                        errorMessage={touched.sureName && errors.sureName}
                        {...field}
                      />
                    )}
                  </Field>
                  <Field name="email">
                    {({ field }: FormFieldType): ReactElement => (
                      <TextInputComponent
                        required
                        containerClassName="container-input col-12 col-xl-6"
                        placeholder="Ingresá tu email"
                        label="Email"
                        type="email"
                        errorMessage={touched.email && errors.email}
                        {...field}
                      />
                    )}
                  </Field>
                  <Field name="password">
                    {({ field }: FormFieldType): ReactElement => (
                      <PasswordInputComponent
                        required
                        containerClassName="container-input col-12 col-xl-6"
                        type="password"
                        placeholder="Ingresá tu contraseña"
                        label="Contraseña"
                        errorMessage={touched.password && errors.password}
                        {...field}
                      />
                    )}
                  </Field>
                  <Field name="countryCode">
                    {({ field }: FormFieldType): ReactElement => (
                      <SelectInputComponent
                        required
                        containerClassName="container-input col-12 col-xl-6"
                        placeholder="¿En dónde estás?"
                        label="País"
                        options={countriesApp.map((item, index) => ({ id: index, name: item.name, value: item.code }))}
                        errorMessage={touched.countryCode && errors.countryCode}
                        onOptionClick={setFieldValue}
                        {...field}
                      />
                    )}
                  </Field>
                  <Field name="activityCode">
                    {({ field }: FormFieldType): ReactElement => (
                      <SelectInputComponent
                        required
                        containerClassName="container-input col-12 col-xl-6"
                        placeholder="Seleccioná tu actividad"
                        onOptionClick={setFieldValue}
                        label="Actividad"
                        options={activity}
                        errorMessage={touched.activityCode && errors.activityCode}
                        {...field}
                      />
                    )}
                  </Field>
                  {values.activityCode === 'contador' && (
                    <div className="notification-container container-input px-3">
                      <div className="notification warning">
                        <div className="notification-header">
                          <BsFillExclamationTriangleFill />
                        </div>
                        <div className="notification-content px-2">
                          <div className="font-xs">
                            {`¡Atención! Al seleccionar esta actividad (Contador) se habilitará el acceso a la 
                            versión de "nubceo Contadores", que disponibiliza la información relevante de 
                            Impuestos, Liquidaciones y Ventas, y permite a los profesionales administrar sus 
                            clientes.`}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  <Field name="operationType">
                    {({ field }: FormFieldType): ReactElement => (
                      <SelectInputComponent
                        required
                        containerClassName="container-input col-12 col-xl-6"
                        placeholder="Seleccioná tu operación"
                        onOptionClick={setFieldValue}
                        label="Contanos como operás"
                        options={operations.map((operation, index) => ({ id: index, name: operation.name, value: operation.value }))}
                        errorMessage={touched.operationType && errors.operationType}
                        {...field}
                      />
                    )}
                  </Field>
                  <Field name="countryCodeNumber">
                    {({ field }: FormFieldType): ReactElement => (
                      <SelectInputComponent
                        required
                        containerClassName="container-input col-12 col-xl-6"
                        placeholder="Cod. Área"
                        onOptionClick={setFieldValue}
                        label="Cod Área"
                        options={countryCodeOptions.map((code, index) => ({
                          id: index,
                          name: code.name,
                          value: code.value,
                        }))}
                        errorMessage={touched.operationType && errors.countryCodeNumber}
                        {...field}
                      />
                    )}
                  </Field>
                  <Field name="phone">
                    {({ field }: FormFieldType): ReactElement => (
                      <TelephoneInput
                        required
                        containerClassName="container-input col-12 col-xl-6"
                        label="Teléfono"
                        errorMessage={touched.phone && errors.phone}
                        customChange={setFieldValue}
                        countryCode={values.countryCode || 'AR'}
                        {...field}
                      />
                    )}
                  </Field>
                </div>
                {values.countryCode && (
                  <Field name="terms">
                    {({ field }: FormFieldType): ReactElement => (
                      <CheckBoxComponent
                        errorMessage={touched.terms && errors.terms}
                        containerClassName="mt-5"
                        requiredOnlyOption
                        {...field}
                        disabled={!values.countryCode}
                      >
                        Leíste y aceptas los{' '}
                        <a className="link" href={regexCountryCodes[values.countryCode || 'default'].terms} target="blank">
                          Términos y condiciones
                        </a>
                      </CheckBoxComponent>
                    )}
                  </Field>
                )}
                <FormButtonComponent className="submit-button mt-5" type="submit" disabled={!dirty || loading === 'pending'}>
                  {loading === 'pending' ? 'Cargando...' : 'Registrarme'}
                </FormButtonComponent>
              </>
            </Form>
          )}
        </Formik>
      </div>
      <RandomImageComponent />
      <div>
        <NeedHelpLink
          // analytics={EVENTS.AUTH.REGISTER.HELP}
          className="mt-4 mt-sm-0 text-end"
          iconFillColor={variables.colorWhite}
          paragraphColor="color-white"
        />
        <p className="copyright font-xs font-weight-light color-white">
          {`© Nubceo ${moment().format('YYYY')}, todos los derechos reservados.`}
        </p>
      </div>
    </div>
  );
};

export default RegisterPage;
