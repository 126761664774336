import React from 'react';
import { useNavigate } from 'react-router-dom';
import { DropdownArrowIconSVG } from '../../assets/svgComponents';

const CouponAdvanceRows = (): React.ReactElement => {
  const navigate = useNavigate();

  const rowClickHandler = (): void => {
    navigate('/advance-settlement');
  };

  return (
    <div>
      <h2 className="advance-settlements-title">Adelanto de Liquidaciones</h2>
      <div className="d-flex flex-row justify-content-end">
        <div className="d-flex flex-row me-5">
          <p className="powered-by-text">powered by</p>
          <p className="powered-by-nubceo-text"> nubceo</p>
        </div>
      </div>
      <select className="mb-5 input-select-month">
        <option value="January">Enero</option>
        <option value="February">Febrero</option>
        <option value="March">Marzo</option>
        <option value="April">Abril</option>
        <option value="May">Mayo</option>
        <option value="June">Junio</option>
        <option value="July">Julio</option>
        <option value="August">Agosto</option>
        <option value="September">Septiembre</option>
        <option value="October">Octubre</option>
        <option value="November">Noviembre</option>
        <option value="December">Diciembre</option>
      </select>
      <div className="d-flex flex-row mb-2 justify-content-between p-2 container-row">
        <div role="none" onClick={(): void => rowClickHandler()}>
          <div className="d-flex flex-row">
            <p className="container-row-date">Fecha</p>
            <p className="ms-2 container-row-date-value"> 05 / 08 / 2024</p>
          </div>
          <div className="d-flex flex-row align-items-center">
            <p className="container-row-detail">Detalles</p>
            <div className="left-arrow">
              <DropdownArrowIconSVG width={12} className="color-black" />
            </div>
          </div>
        </div>
        <div>
          <p className="container-row-detail-value">$275 mil</p>
        </div>
      </div>

      <div className="d-flex flex-row mb-2 justify-content-between p-2 container-row">
        <div role="none" onClick={(): void => rowClickHandler()}>
          <div className="d-flex flex-row">
            <p className="container-row-date">Fecha</p>
            <p className="ms-2 container-row-date-value"> 12 / 08 / 2024</p>
          </div>
          <div className="d-flex flex-row align-items-center">
            <p className="container-row-detail">Detalles</p>
            <div className="left-arrow">
              <DropdownArrowIconSVG width={12} className="color-black" />
            </div>
          </div>
        </div>
        <div>
          <p className="container-row-detail-value">$191 mil</p>
        </div>
      </div>

      <div className="d-flex flex-row mb-2 justify-content-between p-2 container-row">
        <div role="none" onClick={(): void => rowClickHandler()}>
          <div className="d-flex flex-row">
            <p className="container-row-date">Fecha</p>
            <p className="ms-2 container-row-date-value"> 19 / 08 / 2024</p>
          </div>
          <div className="d-flex flex-row">
            <p className="container-row-detail">Detalles</p>
            <div className="left-arrow">
              <DropdownArrowIconSVG width={12} className="color-black" />
            </div>
          </div>
        </div>
        <div>
          <p className="container-row-detail-value">$300 mil</p>
        </div>
      </div>
    </div>
  );
};

export default CouponAdvanceRows;
